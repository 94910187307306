import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Configuring global flags";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "configuring-global-flags",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#configuring-global-flags",
        "aria-label": "configuring global flags permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring global flags`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-flags-in-armeria-are"
        }}>{`What flags in Armeria are`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#implementing-your-own-typeflagsprovider"
        }}>{`Implementing your own type://FlagsProvider`}</a></li>
    </ul>
    <h2 {...{
      "id": "what-flags-in-armeria-are",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-flags-in-armeria-are",
        "aria-label": "what flags in armeria are permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What flags in Armeria are`}</h2>
    <p>{`Flags are global variables that control the behavior of Armeria.
These variables mostly serve one of the following two purposes:`}</p>
    <ol>
      <li parentName="ol">{`Overriding the default system values.`}
        <ul parentName="li">
          <li parentName="ul">{`Overriding `}<a parentName="li" {...{
              "href": "type://FlagsProvider#defaultRequestTimeoutMillis():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html#defaultRequestTimeoutMillis()"
            }}>{`type://FlagsProvider#defaultRequestTimeoutMillis()`}</a>{` changes the server-side timeout of a request.`}</li>
          <li parentName="ul">{`Overriding `}<a parentName="li" {...{
              "href": "type://FlagsProvider#defaultMaxServerNumRequestsPerConnection():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html#defaultMaxServerNumRequestsPerConnection()"
            }}>{`type://FlagsProvider#defaultMaxServerNumRequestsPerConnection()`}</a>{` changes the server-side maximum number of requests
allowed that can be served concurrently through one connection.`}</li>
          <li parentName="ul">{`Overriding `}<a parentName="li" {...{
              "href": "type://FlagsProvider#transportType():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html#transportType()"
            }}>{`type://FlagsProvider#transportType()`}</a>{` changes the transport type that will be used for socket I/O in Armeria.`}</li>
          <li parentName="ul">{`Overriding `}<a parentName="li" {...{
              "href": "type://FlagsProvider#fileServiceCacheSpec():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html#fileServiceCacheSpec()"
            }}>{`type://FlagsProvider#fileServiceCacheSpec()`}</a>{` changes the cache specification of `}<a parentName="li" {...{
              "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
            }}>{`type://FileService`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Enhanced debugging or monitoring.`}
        <ul parentName="li">
          <li parentName="ul">{`Overriding `}<a parentName="li" {...{
              "href": "type://FlagsProvider#reportBlockedEventLoop():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html#reportBlockedEventLoop()"
            }}>{`type://FlagsProvider#reportBlockedEventLoop()`}</a>{` enables to log a warning if blocking calls
such as `}<inlineCode parentName="li">{`CompletableFuture.join()`}</inlineCode>{` are called from an event loop thread.`}</li>
          <li parentName="ul">{`Overriding `}<a parentName="li" {...{
              "href": "type://FlagsProvider#verboseSocketExceptions():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html#verboseSocketExceptions()"
            }}>{`type://FlagsProvider#verboseSocketExceptions()`}</a>{` enables logging for socket exceptions which are mostly harmless.`}</li>
          <li parentName="ul">{`Overriding `}<a parentName="li" {...{
              "href": "type://FlagsProvider#meterRegistry():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html#meterRegistry()"
            }}>{`type://FlagsProvider#meterRegistry()`}</a>{` changes the default `}<inlineCode parentName="li">{`MeterRegistry`}</inlineCode>{` where Armeria records metrics.`}</li>
          <li parentName="ul">{`Overriding `}<a parentName="li" {...{
              "href": "type://FlagsProvider#requestContextLeakDetectionSampler():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html#requestContextLeakDetectionSampler()"
            }}>{`type://FlagsProvider#requestContextLeakDetectionSampler()`}</a>{` enables to request context leak tracing by specifying
a `}<a parentName="li" {...{
              "href": "type://Sampler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/Sampler.html"
            }}>{`type://Sampler`}</a>{`.`}</li>
        </ul>
      </li>
    </ol>
    <p>{`You can also override these properties by setting JVM system properties. For example:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`-Dcom.linecorp.armeria.defaultRequestTimeoutMillis=<long>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`-Dcom.linecorp.armeria.defaultMaxServerNumRequestsPerConnection=<integer>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`-Dcom.linecorp.armeria.reportBlockedEventLoop=<boolean>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`-Dcom.linecorp.armeria.verboseSocketExceptions=<boolean>`}</inlineCode></li>
    </ul>
    <Tip mdxType="Tip">
      <p>{`  Please refer to `}<a parentName="p" {...{
          "href": "type://FlagsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html"
        }}>{`type://FlagsProvider`}</a>{` for the complete list of global flags.`}</p>
    </Tip>
    <h2 {...{
      "id": "implementing-your-own-typeflagsprovider",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#implementing-your-own-typeflagsprovider",
        "aria-label": "implementing your own typeflagsprovider permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing your own `}<a parentName="h2" {...{
        "href": "type://FlagsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html"
      }}>{`type://FlagsProvider`}</a></h2>
    <p>{`Create your own `}<a parentName="p" {...{
        "href": "type://FlagsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html"
      }}>{`type://FlagsProvider`}</a>{` and load it via `}<a parentName="p" {...{
        "href": "https://docs.oracle.com/javase/tutorial/sound/SPI-intro.html"
      }}>{`Java SPI`}</a>{`.
If there are multiple implementations of `}<a parentName="p" {...{
        "href": "type://FlagsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html"
      }}>{`type://FlagsProvider`}</a>{`, the implementation with the highest priority will take precedence over the others.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=MyFlagsProvider.java",
        "filename": "MyFlagsProvider.java"
      }}>{`package com.example.providers;

public class MyFlagsProvider implements FlagsProvider {

  @Override
  public int priority() {
    // The provider with higher value will be evaluated first.
    return 100;
  }

  @Override
  public Long defaultRequestTimeoutMillis() {
      return 5000L;
  }

  @Override
  public TransportType transportType() {
      return TransportType.EPOLL;
  }

  @Override
  public Boolean reportBlockedEventLoop() {
      return true;
  }

  @Override
  public Sampler<? super RequestContext> requestContextLeakDetectionSampler() {
      // Samples all request contexts.
      return Sampler.always();
  }

  @Override
  public MeterRegistry meterRegistry() {
      return PrometheusMeterRegistries.newRegistry();
  }

}
`}</code></pre>
    <p>{`Add the following text file to your classpath or JAR file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text",
        "metastring": "filename=META-INF/services/com.linecorp.armeria.common.FlagsProvider",
        "filename": "META-INF/services/com.linecorp.armeria.common.FlagsProvider"
      }}>{`com.example.providers.MyFlagsProvider
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      